// Import the Bulma framework
@use 'bulma';


// Header styling
header {
  // Hero styling
  &.hero {
    background-image: url('/assets/images/cover.png');
    background-color: var(--bulma-dark);
    background-size: cover;
    background-position: center;
  }
}

// Footer styling
footer {
  background-color: var(--bulma-light);
}

// Content styling
.content {
  // Heading styling
  @for $i from 1 through 6
  {
    h#{$i} {
      font-family: var(--bulma-family-secondary);
      font-weight: var(--bulma-weight-normal);

      @if $i <= 2 {
        color: var(--bulma-primary);
      }
    }
  }

  // Link styling
  a:not(.button) {
    color: var(--bulma-link);
    border-bottom: 1px var(--bulma-link) dotted;

    transition: 0.1s ease-in-out;

    &:active {
      color: var(--bulma-link-dark);
      border-bottom-color: var(--bulma-link-dark);
    }

    &:hover {
      color: var(--bulma-link-dark);
      border-bottom-color: var(--bulma-link-dark);
    }

    // Links in the footer have no border
    .footer > & {
      border-bottom: none;
    }
  }

  // List styling
  ul {
    margin: 0;
  }

  // Buttom styling
  .button {
    border-radius: var(--bulma-radius-rounded);
    padding-inline-start: 1em;

    &.is-fullwidth {
      justify-content: flex-start;
    }
  }
}

// Card styling
.card {
  background-color: var(--bulma-primary-light);
  box-shadow: none !important;
}

// Navbar styling
.navbar {
  font-family: var(--bulma-family-secondary);

  // Centered navbar start
  .navbar-start {
    margin-left: auto;
    margin-right: auto;
  }

  // Navbar burger
  .navbar-burger {
    color: var(--bulma-light);
  }

  // Navbar link
  a.navbar-item {
    color: var(--bulma-light);
    font-weight: var(--bulma-weight-light);
    text-transform: uppercase;

    letter-spacing: 0.1rem;

    &:hover, &:active {
      color: var(--bulma-link-light);
      background-color: var(--bulma-black);
    }
  }

  // Navbar menu
  .navbar-menu {
    background-color: var(--bulma-dark);
  }
}

// Lead paragraph
.is-lead {
  font-family: var(--bulma-family-secondary);
  font-size: var(--bulma-size-5);
}

// Header image modifier
.is-header-image {
  margin-left: auto;
  margin-right: auto;
}

// Background image modifier
.has-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
