// Custom fonts and colors
$font-text: "Roboto", sans-serif;
$font-heading: "Raleway", sans-serif;
$color-primary: rgb(180, 29, 49);
$color-dark: rgb(27, 27, 27);

// Import the Bulma framework
@use 'bulma/sass' with (
  // Custom fonts and colors
  $family-primary: $font-text,
  $family-secondary: $font-heading,
  $primary: $color-primary,
  $dark: $color-dark,
  $link: $color-primary,
  //$link-hover: $link-dark,
  //$link-active: $link-dark,

  // Other variables
  $body-overflow-y: auto,
  $dimensions: 16 24 32 48 64 96 128 192 256,

  $footer-padding: 1.5rem,
);

// Import the fonts
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Raleway:wght@300;400&display=swap';
